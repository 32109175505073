















































import Vue from 'vue'
import TenderStatus from '@/views/Tender/partials/TenderStatus.vue'
import { VueEditor } from 'vue2-editor'

export default Vue.extend({
  name: 'TenderHeader',
  components: { TenderStatus, VueEditor },
  props: {
    procurement: {
      type: Object,
      default: () => {
        return {
          id: 0,
          name: '',
          type: '',
          status: '',
          timeRemaining: 0,
          description: '',
          publishDate: '',
          endDate: '',
          publishTime: '',
          endTime: '',
          lots: [],
          creator: undefined,
        }
      },
    },
  },
  computed: {
    creatorName(): string {
      if (this.procurement.creator) return `${this.procurement.creator.firstName} ${this.procurement.creator.lastName}`
      return ''
    },
  },
})
