


















































import Vue from 'vue'
import { TenderDTO, TenderOfferDTO } from '@/services/tender/types'
import { ingoingDirection } from '@/constants/capacityDirections'
import TenderBid from '@/views/Tender/components/TenderBid.vue'
import TenderHeader from '@/views/Tender/components/TenderHeader.vue'
import TenderService from '@/services/tender'
import { sortLots } from '@/utils/helpers'
import CancelButton from '@/components/buttons/CancelButton.vue'
import { ProcurementStatus } from '@/services/myTender/types'

export default Vue.extend({
  name: 'TenderDetails',
  components: { CancelButton, TenderHeader, TenderBid },
  data: function (): {
    id: string
    ingoingDirection: string[]
    selected: number
    procurement: TenderDTO
    data: TenderOfferDTO
    status: string
    redirectedFrom: string
    ProcurementStatus: typeof ProcurementStatus
  } {
    const id = 'id' in this.$route.params && this.$route.params.id
    const status = 'status' in this.$route.query && this.$route.query.status
    const redirectedFrom = 'redirectedFrom' in this.$route.query && this.$route.query.redirectedFrom
    if (!id) {
      throw new Error('EvaluateSellingTender is missing required route param id')
    }
    return {
      ProcurementStatus,
      id,
      ingoingDirection,
      selected: 0,
      status: status ? (status as string) : '',
      redirectedFrom: redirectedFrom ? (redirectedFrom as string) : '',
      procurement: {
        id: 0,
        name: '',
        type: '',
        status: '',
        timeRemaining: 0,
        description: '',
        publishDate: '',
        endDate: '',
        publishTime: '',
        endTime: '',
        lots: [],
        offersReceived: undefined,
        creator: undefined,
      },
      data: {
        procurementId: Number(id),
        offeredCbc: null,
        cbcDirection: null,
        lotBids: [],
      },
    }
  },
  computed: {
    selectedLot(): number {
      return this.selected ? this.selected : 0
    },
  },
  methods: {
    async fetchItem(): Promise<void> {
      const viewableStatuses = [ProcurementStatus.PUBLISHED, ProcurementStatus.DECRYPTING_OFFERS]
      if (viewableStatuses.includes(this.status as ProcurementStatus)) {
        const { data } = await TenderService.published(this.id)
        this.procurement = data.data
        this.data.lotBids = data.data.lots.map((lot) => ({
          startTime: '',
          endTime: '',
          ...lot,
          lotId: lot.id,
          offeredPower: 0,
          pricePerUnit: 0,
          isConditional: true,
          direction: lot.direction,
          cbcOffered: lot.cbcCoverage ? lot.crossBorderCapacity?.leftoverCapacity : null,
        }))
        sortLots(this.data.lotBids)
        return
      }
      const { data } = await TenderService.winners(this.id)
      this.procurement = data.data
      this.data.lotBids = data.data.lots.map((lot) => ({
        ...lot,
        lotId: lot.id,
        offeredPower: 0,
        pricePerUnit: 0,
        isConditional: true,
        direction: lot.direction,
        cbcOffered: lot.cbcCoverage ? lot.crossBorderCapacity?.leftoverCapacity : null,
      }))

      sortLots(this.data.lotBids)
    },
  },
  mounted() {
    this.fetchItem()
  },
})
